/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
} from '../../../../../../_metronic/helpers'
import {getCompanies} from '../../../company-management/companies-list/core/_requests'
import {useQueryRequest} from './QueryRequestProvider'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'

const QueryResponseContext = createResponseContext(initialQueryResponse)
const QueryResponseProvider = ({children}) => {
  const {state} = useQueryRequest()
  const navigate = useNavigate()
  const [query, setQuery] = useState(state)
  const updatedQuery = useMemo(() => state, [state])
  const [filterCompanies, setFilterCompanies] = useState([])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {isFetching, refetch, data} = useQuery(
    'companies',
    async () => {
      const changingPerPage = {
        ...query,
        items_per_page: 5,
      }

      try {
        const result = await getCompanies(changingPerPage)

        if (result.status === 200) {
          setFilterCompanies(result.data)
          return result.data
        } else if (result?.response?.status === 401) {
          localStorage.removeItem('token')
          navigate('/auth/login')
        } else {
          throw new Error(result?.response?.data?.message)
        }
      } catch (error) {
        toast.error(error?.message, {
          autoClose: 10000,
        })

        return error.message
      }
    },

    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  const searchByUsernName = (searchText) => {
    setFilterCompanies(
      [...data].filter((user) => {
        if (user.name.toLowerCase().includes(searchText.toLowerCase())) {
          return user
        }
        return false
      })
    )
  }

  return (
    <QueryResponseContext.Provider
      value={{isLoading: isFetching, refetch, filterCompanies, searchByUsernName, query}}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {filterCompanies} = useQueryResponse()

  if (filterCompanies.length === 0) {
    return []
  }

  return filterCompanies || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {filterCompanies} = useQueryResponse()
  if (!filterCompanies || !filterCompanies.payload || !filterCompanies.payload.pagination) {
    return defaultPaginationState
  }

  return filterCompanies.payload.pagination
}

const useQueryResponseLoading = () => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
